import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import styled from "@emotion/styled";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {useTranslation} from "react-i18next";
import AccordionDialogue from "./AccordionDialogue";

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

type Reply = {
    sentence: string;
    audio_url: string;
    phrase:string;
};

type Dialogue = {
    title: string;
    create_date: string;
    language_code: string;
    language_label: string,
    replies: Reply[];
}

const DialogueList = () => {
    const [dialogues, setDialogues] = useState<Dialogue[]>([]);
    const [endOfData, setEndOfData] = useState<boolean>(false);
    const lastKeyRef = useRef(null);  // Utilisez useRef au lieu de useState
    const { shakeButton } = useContext(ShakeButtonContext);
    const loadingRef = useRef<HTMLDivElement>(null);  // Ref pour l'élément de chargement

    const { t } = useTranslation();

    const observer = useRef(new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !endOfData) {
            fetchDialogues();  // Charge plus de vidéos lorsque l'élément de chargement est visible et que endOfData est false
        }
    }, {
        threshold: 1  // Déclenche l'observer lorsque 100% de l'élément est visible
    }));


    const fetchDialogues = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        type JWTDeCode = {
            sub: string,
            email: string,
            iat: number,
            exp: number
        }

        const decodedToken: JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        try {
            const response = await axiosInstance.get('/dialogue-list/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startKey: lastKeyRef.current  // Utilisez lastKeyRef.current au lieu de lastKey
                },
            });
            setDialogues(prevDialogues => [...prevDialogues, ...response.data.dialogues]);

            lastKeyRef.current = response.data.last_evaluated_key;

            setEndOfData(response.data.end_of_data);
            // Si endOfData est vrai, supprimez l'IntersectionObserver
            if (response.data.endOfData && loadingRef.current) {
                observer.current.unobserve(loadingRef.current);
            }

        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }

    };

    useEffect(() => {
        fetchDialogues().then(() => {
            const currentLoadingRef = loadingRef.current;
            const currentObserver = observer.current;

            if (currentLoadingRef) {
                currentObserver.observe(currentLoadingRef);
            }

            return () => {
                if (currentLoadingRef) {
                    currentObserver.unobserve(currentLoadingRef);
                }
            };
        });
    }, []);


    return (
        <MainContainer>
            {Object.values(dialogues).length === 0 ? ( endOfData &&
                <p>{t('noDownloadsAvailable')}</p>
            ) : (
                Object.values(dialogues).map((dialogue) => (
                    <AccordionDialogue dialogue={dialogue} />
                )))
            }
            {/* Affichez "Loading..." uniquement si endOfData est faux */}
            {!endOfData && <div ref={loadingRef}>Loading...</div>}

        </MainContainer>
    );
};

export default DialogueList;
