import React, {useContext, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';
import {ReactComponent as SendIcon} from './icons/send_fill1_24px.svg';
import { ReactComponent as MenuIcon } from './icons/menu_fill1_24px.svg';
import { ReactComponent as SendIconSVG } from './icons/send_fill1_24px.svg';
import { ReactComponent as LanguageIcon } from './icons/language_fill1_20px.svg';
import { ReactComponent as PlayIcon } from './icons/play_circle_fill1_24px.svg';
import {useNavigate} from "react-router-dom";
import YouTubeEmbed from "./YoutubeEmbedded";
import {useList} from "./store/ListContext";
import jwtDecode from "jwt-decode";
import {ShakeButtonContext} from "./ShakeButtonContext";
import i18n from "./i18n";
import useWebSocket from "react-use-websocket";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Card, CardContainer} from "./CardStyles";
import DialogueListContainer from "./DialogueListContainer";
import { IconButton } from '@mui/material';

interface FooterContainerProps {
    disabled?: boolean; // Propriété disabled optionnelle de type boolean
}

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

const MainContainer2 = styled.div`
    padding-bottom: 0px;  // Adjust as needed
    padding-top: 0px;
    @media (max-width: 600px) {
        padding-top: 0px;
    }
`;

const AccordionContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-left: 10px;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const VideoContainer2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 70%;
`;


const ContainerL1 = styled.div`
    position: absolute;
    top: 4px;
    right: 10%;
    display: flex;
    flex-direction: row;
    align-items: center; /* Centrer verticalement */
    gap: 3px; /* Espacement horizontal entre les selects */
    
    @media only screen and (max-width: 800px) {
        right: 2%;
    }
`;

const SelectGroup = styled.div`
    display: flex;
    flex-direction: column; /* Aligner verticalement le StoryPrompt et le Select */
    align-items: flex-start; /* Aligner le texte et le select à gauche */
    margin-right: 5px; /* Espacement entre chaque groupe */
`;

const FooterContainer = styled.div<FooterContainerProps>`
    background-color: #f2efff;
    position: fixed;
    bottom: 0;
    left: 50%; /* Déplace la gauche du bloc au milieu de son parent */
    transform: translateX(-50%); /* Déplace le bloc horizontalement de moitié de sa largeur vers la gauche */
    width: 100%; /* Largeur du FooterContainer */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 110px; /* Hauteur du FooterContainer */
    z-index: 1; /* Assure que le FooterContainer est au-dessus du reste du contenu */
    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;

const StoryPrompt = styled.span`
    font-size: 12px;
    padding-bottom: 3px;
    font-family: Space Grotesk,sans-serif;
    margin-right: 10px;
    color: #999999;
`;

const StoryTextArea = styled.textarea`
    position: absolute;
    bottom: 10px;
    flex-grow: 1;
    padding: 7px;
    border-radius: 10px;
    width: 80%;
    height: 45px;
    border: #f2efff;

    @media (max-width: 800px) {
        width: 95%;
    }
`;

const StyledSendIcon = styled(SendIconSVG)<SendIconProps>`
    position: absolute;
    right: 10%;
    bottom: 10px;
    fill: #6149c1;
    cursor: pointer;
    @media (max-width: 800px) {
        right: 10px;
    }
`;


const Select = styled.select`
    right: 1px;
    font-size: 12px;
    padding: 4px;
    border-radius: 10px;  // rounded border
    border: 1px solid #000000; // purple border
    @media (max-width: 800px) {
        font-size: 12px;
    }
`;

const DivContainer = styled.div`
    margin-top: 100px;
    margin-left: 10px;
`;

const LiContainer = styled.li`
    margin-bottom: 10px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center; /* Centrer verticalement */
    width: 70%;
    flex-wrap: wrap;
    padding-bottom: 90px;  // Ajuster au besoin
    padding-top: 20px;
    margin: 0 auto; /* Ajout pour centrer horizontalement */
    @media only screen and (max-width: 800px) {
        align-items: initial; /* Réinitialiser l'alignement vertical pour les écrans de taille mobile */
        justify-content: center; /* Centrer horizontalement pour les écrans de taille mobile */
        width: 100%;
    }
`;

const SpanTitle = styled.span`
    color: red;
    display: flex; /* Nécessaire pour align-items */
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    text-align: center; /* S'assure que le texte lui-même est centré */
    height: 100%; /* Facultatif : garantit que l'élément a une hauteur suffisante */
`;

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const languageNameToCode: Record<LanguageOption, string> = {
    'english': 'en',
    'spanish': 'es',
    'arabic': 'ar',
    'french': 'fr',
    'german': 'de',
    'chinese': 'zh',
    'japanese': 'ja',
    'hindi': 'hi',
    'turkish': 'tr',
    'korean': 'ko',
    'italian': 'it',
    'dutch': 'nl',
    'russian': 'ru',
    'indonesian': 'id',
};

interface SendIconProps {
    isRtl: boolean;
    onClick: () => Promise<void>;
    // Ajoutez d'autres props personnalisées si nécessaire
}
type MediaResult = {
    audio_url: string;
    sentence: string;
    phrase:string;
};
type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}
const Home = () => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [dialogue, setDialogue] = useState<(MediaResult)[]>([]);
    const [text, setText] = useState<string>("");

    const { shakeButton } = useContext(ShakeButtonContext);

    const { t } = useTranslation();

    const currentLanguage = i18n.language;
    const initialLanguage = 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);
    const [languageCode, setLanguageCode] = useState<string>(initialLanguage);
    const { setList } = useList();

    const lang = localStorage.getItem('language');
    const isRtl = lang === 'ar';

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [speed, setSpeed] = useState("1.0");
    const [complexity, setComplexity] = useState("1");

    const [loadingResult, setLoadingResult] = useState(false);

    const [isVideoVisible, setIsVideoVisible] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false); // Contrôle l'ouverture du modal

    const [errorMessage, setErrorMessage] = useState(undefined);

    const openModal = () => setIsModalOpen(true); // Ouvre le modal
    const closeModal = () => setIsModalOpen(false); // Ferme le modal


    const handleSpeed = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSpeed(event.target.value);
    };

    const handleComplexity = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setComplexity(event.target.value);
    };

    const toggleVideo = () => {
        setIsVideoVisible(!isVideoVisible);
    };

    let wssUrl;
    if (token) {
        wssUrl = `wss://wss.assist.studio/?token=${token}`
    } else {
        wssUrl = `wss://wss.tmp.imlingo.com`
    }

    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(wssUrl, {
        onOpen: () => {
            console.log('opened');
        },
        shouldReconnect: (closeEvent) => true,
        onClose: event => {
            console.error('onClose:', event);
            setLoadingResult(false);
        },
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            setTimeout(() => {
                //window.location.reload();
            }, 10000);
        }
    });

    useEffect(() => {
        console.log('useEffect');
        Prism.highlightAll();
        if (token) {
            navigate('/conversation');
        }
    }, []);

    useEffect(() => {
        console.log('useEffect : result');
        if (result) {
            setResult(result);
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [result]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);

                if (!eventData.message && !eventData.phrases) {
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                    if(result) {
                        setDialogue(prevDialogue => [...prevDialogue, result]);
                    }
                    setLoadingResult(false);
                }

                if (eventData.phrases) {
                    setList(eventData.phrases);
                }

                if (eventData.message === "Success") {
                    setLoadingResult(false);
                }
                if (eventData.message === "creditExhaustedError" || eventData.message === "Internal server error") {
                    console.log('creditExhaustedError');
                    setLoadingResult(false);
                }

                if (eventData.type === "error") {
                    console.log(eventData.message);
                    setLoadingResult(false);
                    setErrorMessage(eventData.message);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
                setLoadingResult(false);
            }
        }
    }, [lastMessage]);

    const handleLanguageChange = (selectedLanguage: LanguageOption) => {
        setLanguage(selectedLanguage);
        const lang = languageNameToCode[selectedLanguage];
        setLanguageCode(lang);
        console.log(languageCode);
    };

    const submitText = async () => {
        setResult(null);
        setDialogue([]);
        setList([]);

        let user_id = undefined;
        if (token) {
            const decodedToken : JWTDeCode = jwtDecode(token);
            user_id = decodedToken.sub;
        }

        const payload = {
            subject: text,
            language: languageCode,
            my_language: languageCodeToName[currentLanguage],
            my_language_code: currentLanguage,
            action: 'dialogue',
            user_id: user_id,
            speed: speed,
            complexity: complexity
        };

        sendMessage(JSON.stringify(payload));

        setLoadingResult(true);
    };

    return (
        <div>
            <MainContainer style={{display: dialogue.length == 0 && !loadingResult ? 'block' : 'none'}}>
                {t('videoTutoId') && t('videoTutoId') != 'videoTutoId' ? (
                        <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                            <VideoContainer>
                                <YouTubeEmbed videoId={t('videoTutoId')}/>
                            </VideoContainer>
                        </AccordionContainer>) :
                    (<AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                        <p>
                            <Trans i18nKey="menuLabel" components={[<MenuIcon/>]}/>
                        </p>
                        <ul>
                            <LiContainer>+ {t('conversation')} : {t('allowGenerateConversation')}</LiContainer>
                            <LiContainer>+ {t('story')} : {t('allowGenerateStory')}</LiContainer>
                            <LiContainer>{t('conversations')} : {t('displayMyConversations')}</LiContainer>
                            <LiContainer>{t('myStories')} : {t('displayMyStories')}</LiContainer>
                            <LiContainer>{t('languages')} : {t('choiceMyLanguage')}</LiContainer>
                        </ul>
                    </AccordionContainer>)
                }

            </MainContainer>
            <MainContainer2 style={{display: dialogue.length > 0 ? 'block' : 'none'}}>
                <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                    <VideoContainer2>
                        <IconButton onClick={openModal} style={{marginRight: '5%'}}>
                            <PlayIcon/>
                        </IconButton>
                        <Typography variant="body2" color="textSecondary" style={{fontSize: '0.75rem'}}>
                            {t('howItWorks')}
                        </Typography>
                    </VideoContainer2>

                    {/* Modal (Popup) pour afficher la vidéo */}
                    <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="md">
                        <DialogContent>
                            <YouTubeEmbed videoId={t('videoTutoId')}/>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={closeModal}>{t('close')}</button>
                        </DialogActions>
                    </Dialog>
                </AccordionContainer>

            </MainContainer2>

            <SpanTitle style={{display: errorMessage ? 'block' : 'none'}}>{errorMessage}</SpanTitle>

            <Container style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                {loadingResult && (
                    <CircularProgress disableShrink={true}/>
                )}
                {dialogue.length > 0 && (
                    <CardContainer>
                        <Card>
                            <DialogueListContainer dialogue={dialogue} lang={languageCode} language={language}/>
                        </Card>
                    </CardContainer>
                )}
            </Container>

            <FooterContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                <ContainerL1>
                    <SelectGroup>
                        <StoryPrompt>{t('conversationIn')}</StoryPrompt>
                        <Select value={language}
                                onChange={(e) => handleLanguageChange(e.target.value as LanguageOption)}>
                            <option value=""><LanguageIcon/></option>
                            {LANGUAGE_OPTIONS.map((lang) => (
                                <option key={lang} value={lang}>
                                    {t(lang)}
                                </option>
                            ))}
                        </Select>
                    </SelectGroup>
                    <SelectGroup>
                        <StoryPrompt>{t('speed')}</StoryPrompt>
                        <Select value={speed} onChange={handleSpeed}>
                            <option value="0.6">{t('speed_very_slow')}</option>
                            <option value="0.8">{t('speed_slow')}</option>
                            <option value="1.0">{t('speed_normal')}</option>
                            <option value="1.2">{t('speed_fast')}</option>
                            <option value="1.4">{t('speed_very_fast')}</option>
                        </Select>
                    </SelectGroup>
                    <SelectGroup>
                        <StoryPrompt>{t('level')}</StoryPrompt>
                        <Select value={complexity} onChange={handleComplexity}>
                            <option value="0">{t('level_easy')}</option>
                            <option value="1">{t('level_intermediate')}</option>
                            <option value="2">{t('level_advanced')}</option>
                        </Select>
                    </SelectGroup>

                </ContainerL1>
                <StoryTextArea value={text} onChange={e => setText(e.target.value)}
                               placeholder={t('conversationPlaceholder') as string}>
                </StoryTextArea>
                <StyledSendIcon onClick={submitText} isRtl={isRtl}>{t('send')}</StyledSendIcon>

            </FooterContainer>
        </div>
    );
};

export default Home;
