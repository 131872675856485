import React, {useState} from "react";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactDOM from "react-dom";
import Header from "./Header";
import {ShakeButtonContext} from "./ShakeButtonContext";
import Home from "./Home";
import DialogueList from "./DialogueList";
import {ListProvider} from "./store/ListContext";
import {TokenProvider} from "./store/TokenContext";
import Dialogue from "./Dialogue";
import Account from "./Account";
import Story from "./Story";
import StoryList from "./StoryList";


const clientId = process.env.REACT_APP_CLIENT_ID || '606303163032-qihpuc49c8habhivuh4tf06olpq6t1qf.apps.googleusercontent.com';

const App = () => {

    const [shouldShakeButton, setShouldShakeButton] = useState(false);

    const shakeButton = () => {
        setShouldShakeButton(true);
        setTimeout(() => setShouldShakeButton(false), 10000);  // Stop shaking after 1 second
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ShakeButtonContext.Provider value={{ shouldShakeButton, setShouldShakeButton, shakeButton }}>
                <TokenProvider>
                    <ListProvider>
                    <Router>
                        <div>
                            <Header />
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/conversations" element={<DialogueList />} />
                                <Route path="/conversation" element={<Dialogue />} />
                                <Route path="/story" element={<Story />} />
                                <Route path="/stories" element={<StoryList />} />
                                <Route path="/account" element={<Account />} />

                            </Routes>
                        </div>
                    </Router>
                    </ListProvider>
                </TokenProvider>
            </ShakeButtonContext.Provider>
        </GoogleOAuthProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
export default App;
