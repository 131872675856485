import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import Prism from "prismjs";
import 'prismjs/themes/prism-solarizedlight.css';
import {Card, CardContainer} from "./CardStyles";
import DialogueListContainer from "./DialogueListContainer";

const AccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const AccordionTitle = styled.div`
    width: 90%; /* Occupy the full width available */
    padding: 10px;
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 50px;
`;

const AccordionContent = styled.div`
    margin-top: 5px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const RecordTitle = styled.div`
    margin-bottom: 5px;
    background-color: #fbfbfb;
    font-size: 12px;
`;

type UserInfoType = {
    "user_id": string,
    "created_at": string,
    "given_name": string,
    "family_name": string,
    "email": string,
    "picture": string,
    "name": string
}

const Account = () => {
    const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);

    const { t } = useTranslation();
    const language = localStorage.getItem('language');
    const isRtl = language === 'ar';

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []);

    return (
        <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
            <AccordionTitle>
                <div>
                    <div style={{marginBottom: '10px', marginTop: '10px'}}>{userInfo?.email}</div>
                    <RecordTitle>{userInfo ? (userInfo.name || userInfo.family_name) : null}</RecordTitle>
                </div>
            </AccordionTitle>
        </AccordionContainer>
    );
};

export default Account;
