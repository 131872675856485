import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import Prism from "prismjs";
import 'prismjs/themes/prism-solarizedlight.css';
import {Card, CardContainer} from "./CardStyles";
import DialogueListContainer from "./DialogueListContainer";
import StoryListContainer from "./StoryListContainer";

const AccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const AccordionTitle = styled.div`
    width: 90%; /* Occupy the full width avœailable */
    padding: 10px;
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: 5px;
`;

const AccordionContent = styled.div`
    margin-top: 5px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 90%;
`;

const RecordTitle = styled.div`
    margin-bottom: 5px;
    background-color: #fbfbfb;
    font-size: 12px;
`;

interface Reply {
    sentence: string;
    audio_url: string;
    phrase:string;
};

interface Dialogue {
    title: string;
    create_date: string;
    language_code: string;
    language_label: string,
    replies: Reply[];
}

interface DialogueProps {
    dialogue: Dialogue;
    all: boolean;
}

const StoryAccordionDialogue: React.FC<DialogueProps> = ({ dialogue , all}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();
    const language = localStorage.getItem('language');
    const isRtl = language === 'ar';

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
            <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
                <div>
                    <div style={{ marginBottom: '10px'}}>{dialogue.title}</div>
                    <RecordTitle>{dialogue.create_date}</RecordTitle>
                </div>
                <div>{isOpen ? '-' : '+'}</div>
            </AccordionTitle>
            {isOpen && (
                <AccordionContent>
                    <CardContainer>
                        <Card>
                            <StoryListContainer dialogue={dialogue.replies} lang={dialogue.language_code} language={language} all={all} />
                        </Card>
                    </CardContainer>

                </AccordionContent>
            )}
        </AccordionContainer>
    );
};

export default StoryAccordionDialogue;
