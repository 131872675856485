import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as MenuIcon } from './icons/menu_fill1_24px.svg';
import styled from "@emotion/styled";
import {FooterItem} from "./CardStyles";
import {Link} from "react-router-dom";
import {ReactComponent as AccountIcon} from "./icons/person_24px.svg"
import {ReactComponent as AddCircleIcon} from "./icons/add_24px.svg"
import {ReactComponent as ConversationsIcon} from "./icons/format_list_bulleted_24px.svg"
import {ReactComponent as LogoutIcon} from "./icons/logout_24px.svg"
import {ReactComponent as LanguageIcon} from "./icons/language_24px.svg"
import {ReactComponent as StoriesIcon} from "./icons/reorder_fill1_24px.svg"

const MenuContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 15px;
`;
const MenuPanel = styled.div`
    position: absolute;
    top: 25px;
    left: 10px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    z-index: 1;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    font-size: 13px;
    color: #6149c1;
`;
const StyledSelect = styled.select`
    padding: 5px;
    border: 0px solid #fff;
    background-color: #fff;
    font-size: 13px;
    color: #6149c1;
`;
export const FooterLabel = styled.span`
    color: #000000;
`;
interface AvatarProps {
    userImage: string | null;
    userName: string | null;
    setUserInfo: any;
    handleSignOut: any;
    userInfo: any
}

const Menu = ({userImage, userName, setUserInfo, handleSignOut, userInfo}: AvatarProps) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const menuContainer = document.getElementById('menu-container');
            if (menuContainer && !menuContainer.contains(event.target as Node)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleShowUserInfo = () => {
        toggleDropdown();
        navigate('/account');
    };

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('language', event.target.value);
        setSelectedLanguage(event.target.value);
    };

    const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to prevent closing the menu
    };

    const handleGenerateNewDialogue = () => {
        toggleDropdown();
        const isCreateProductPage = location.pathname === '/conversation';
        if (isCreateProductPage) {
            window.location.reload();
        } else {
            navigate('/conversation');
        }
    };

    const handleGenerateNewStory = () => {
        toggleDropdown();
        const isCreateProductPage = location.pathname === '/story';
        if (isCreateProductPage) {
            window.location.reload();
        } else {
            navigate('/story');
        }
    };

    const handleDialogues = () => {
        toggleDropdown();
        navigate('/conversations');
    };

    const handleStories = () => {
        toggleDropdown();
        navigate('/stories');
    };

    return (
        <MenuContainer id="menu-container" onClick={toggleDropdown}>
            <MenuIcon style={{top: '55px', fill: '#6149c1'}} />
            {dropdownVisible && (
                <MenuPanel onClick={handleDropdownClick}>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
                         onClick={handleShowUserInfo}>
                        <AccountIcon style={{width: '30px', height: '30px', borderRadius: '10px', fill: '#6149c1'}}/>
                        <p style={{cursor: 'pointer'}}>{t('myAccount')}</p>
                    </div>
                    <hr style={{margin: '5px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
                         onClick={handleGenerateNewDialogue}>
                        <AddCircleIcon style={{width: '30px', height: '30px', borderRadius: '10px', fill: '#6149c1'}}/>
                        <p style={{cursor: 'pointer'}}>
                            {t('conversation')}
                        </p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
                         onClick={handleGenerateNewStory}>
                        <AddCircleIcon style={{width: '30px', height: '30px', borderRadius: '10px', fill: '#6149c1'}}/>
                        <p style={{cursor: 'pointer'}}>
                            {t('story')}
                        </p>
                    </div>
                    <hr style={{margin: '5px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
                         onClick={handleDialogues}>
                        <ConversationsIcon style={{width: '30px', height: '30px', fill: '#6149c1'}}/>
                        <p style={{cursor: 'pointer', marginBottom: '0px', marginTop: '0px'}}>
                            {t('conversations')}
                        </p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
                         onClick={handleStories}>
                        <StoriesIcon style={{width: '30px', height: '30px', fill: '#6149c1'}}/>
                        <p style={{cursor: 'pointer', marginBottom: '0px', marginTop: '0px'}}>
                            {t('myStories')}
                        </p>
                    </div>
                    <hr style={{margin: '5px 0'}}/>

                    <p style={{paddingLeft: '10px', paddingRight: '10px', marginBottom: '30px'}}></p>
                    <hr style={{margin: '5px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                        <LanguageIcon style={{width: '30px', height: '30px', fill: '#6149c1'}}/>
                        <StyledSelect value={selectedLanguage} onChange={changeLanguage}>
                            <option value="en">English</option>
                            <option value="fr">Français</option>
                            <option value="es">Español</option>
                            <option value="ar">العربية</option>
                            <option value="de">Deutsch</option>
                            <option value="zh">中文</option>
                            <option value="hi">हिन्दी</option>
                            <option value="ja">日本語</option>
                            <option value="it">Italiano</option>
                            <option value="ko">한국어</option>
                            <option value="nl">Nederlands</option>
                            <option value="tr">Türkçe</option>
                            <option value="ru">Русский</option>
                            <option value="id">Bahasa</option>
                        </StyledSelect>
                    </div>

                    <hr style={{margin: '5px 0'}}/>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                        <LogoutIcon style={{width: '20px', height: '20px', fill: '#6149c1'}}/>
                        <p style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            marginTop: '5px',
                            marginBottom: '5px',
                            cursor: 'pointer',
                            fontSize: 'small'
                        }}
                           onClick={handleSignOut}>{t('signOut')}</p>
                    </div>

                </MenuPanel>
            )}
        </MenuContainer>
    )
}

export default Menu;
