import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ReactComponent as TranslateIcon } from './icons/translate_fill1_20px.svg';
import { ShakeButtonContext } from "./ShakeButtonContext";

import { useList } from './store/ListContext'
import i18n from "./i18n";

interface AudioPlayerProps {
    url: string;
    sentence: string;
    phrase: string;
    index: number;
    isPlaying: boolean;
    playAudioFromIndex: () => void;
    onEnd: () => void;
    language: string | null;
    lang: string | null;
    all: boolean | false;
}

interface Translates {
    [key: number]: { sentence: string, exists: boolean };
}

const StoryAudioPlayer: React.FC<AudioPlayerProps> = ({ url, sentence, phrase, isPlaying, index, onEnd, playAudioFromIndex, language, lang, all }) => {
    const [playing, setPlaying] = useState(false);
    const playerRef = useRef(null);
    const [translates, setTranslates] = useState<Translates>({});
    const [traduction, setTraduction] = useState<boolean>(false);
    const [showIcon, setShowIcon] = useState(false);
    const { shakeButton } = useContext(ShakeButtonContext);
    const { list } = useList();
    const currentLanguage = i18n.language;
    const isRtlUser = currentLanguage === 'ar';
    const isRtlTo = lang === 'ar';
    let content;
    let bouton;

    useEffect(() => {
        if (Object.keys(translates).length === 0) {
            const newTranslates: Translates = {};
            list.forEach((sentence: string, ind: number) => {
                newTranslates[ind] = { sentence, exists: false };
                setShowIcon(true);
            });
            setTranslates(newTranslates);
        }
    }, [list]);

    const handlePlayPause = () => {
        setPlaying(!playing);
        if (!playing) {
            playAudioFromIndex();
        }
    };

    const translateTo = (e: React.MouseEvent) => {
        e.stopPropagation();
        const entry = translates[index];
        if (entry) {
            setTranslates(prevMap => ({
                ...prevMap,
                [index]: {
                    ...prevMap[index],
                    exists: !entry.exists
                }
            }));
        }
    };

    const traductionTo = (e: React.MouseEvent) => {
        e.stopPropagation();
        setTraduction(!traduction);
    };

    useEffect(() => {
        setPlaying(isPlaying);
    }, [isPlaying]);

    const renderSentenceWithBold = (text: string) => {
        return <>
            {<span>
                {text}
                {all && phrase && (<TranslateIcon onClick={traductionTo}
                               style={{ cursor: 'pointer' }} />)}
                {!all && showIcon && (<TranslateIcon onClick={translateTo}
                                                    style={{ cursor: 'pointer' }} />)}
                </span>}
        </>
    };

    if (phrase && phrase.length > 0) {
        content = (
            <td style={{ width: '100%', cursor: 'pointer' }} onClick={handlePlayPause}>
                {renderSentenceWithBold(sentence.replace(/[""]/g, ''))}
                <span style={{ color: 'green', display: traduction ? 'block' : 'none' }} dir={isRtlUser ? 'rtl' : 'ltr'}>
                    {phrase}
                </span>
            </td>
        );
    } else {
        content = (
            <td style={{ width: '90%', cursor: 'pointer' }} onClick={handlePlayPause}>
                {renderSentenceWithBold(sentence.replace(/[""]/g, ''))}
                <span style={{ color: 'green' }} dir={isRtlUser ? 'rtl' : 'ltr'}>
                    {translates[index]?.exists == true ? <br /> : null}
                    {translates[index]?.exists == true ? translates[index]?.sentence : null}
                </span>
            </td>
        );
    }

    return (
        <table style={{ width: '100%', direction: isRtlTo ? 'rtl' : 'ltr' }}>
            <tr>
                {content}
                {bouton}
            </tr>
            <tr>
                <td colSpan={2}>
                    <ReactPlayer
                        ref={playerRef}
                        url={url}
                        playing={playing}
                        controls={false}
                        width='0'
                        height='0'
                        onEnded={onEnd}
                    />
                </td>
            </tr>
        </table>
    );
};

export default StoryAudioPlayer;
